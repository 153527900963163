import { useCurrentUser } from '@capturi/core'
import {
  FilterComponentOptions,
  FilterCriteriaButton,
  FilterDefinitions,
  FocusableElement,
  useFilterDefinitions,
} from '@capturi/filters'
import {
  Button,
  Caption,
  FormLabel,
  Highlight,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Grid,
  Icon,
  MenuDivider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  StackDivider,
  Switch,
  Tooltip,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { MdAdd, MdInfoOutline } from 'react-icons/md'

import { DEFAULT_PARAMETER_WEIGHT, MAX_PARAMETER_WEIGHT } from '../constants'
import {
  FilterValueKeys,
  ScoreFormModel,
  ScoreParameterFormModel,
} from '../types'
import { ScoreGoalInput } from './ScoreGoalInput'
import TotalWeight from './TotalWeight'

const templateColumns = { lg: 'minmax(0, 2fr) 1fr' }

const Parameters: React.FC<{
  goal: ScoreFormModel['goal']
  params: ScoreParameterFormModel[]
  onGoalChange: (state: Partial<ScoreFormModel>) => void
  onParameterChange: (state: ScoreParameterFormModel[]) => void
}> = ({ goal, params, onGoalChange, onParameterChange: _setParameters }) => {
  const currentUser = useCurrentUser()

  //Seperate into own file
  const filterDefinitions = useFilterDefinitions(currentUser, {
    teamUids: {},
    userUids: {},
    subjects: {
      componentOptions: {
        isMulti: true,
      } as FilterComponentOptions['subjects'],
    },
    labels: {
      componentOptions: {
        isMulti: true,
      } as FilterComponentOptions['labels'],
    },
    trackers: {},
    notTrackers: {},
    customProp1: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp1'],
    },
    customProp2: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp2'],
    },
    customProp3: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp3'],
    },
    customProp4: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp4'],
    },
    customProp5: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp5'],
    },
    customProp6: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp6'],
    },
    customProp7: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp7'],
    },
    customProp8: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp8'],
    },
    customProp9: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp9'],
    },
    customProp10: {
      componentOptions: {
        isMulti: false,
      } as FilterComponentOptions['customProp10'],
    },
    scores: {
      isAvailable: () => false,
    },
    repeatCalls: {
      isAvailable: () => false,
    },
    keyTopics: {
      isAvailable: () => false,
    },
    qaIsReviewed: {
      isAvailable: () => false,
    },
  })

  const isToggled = !!goal

  const [toggleGoal, setToggleGoal] = useState(isToggled)

  const setOrRemoveVal = (index: number, value: string | null): void => {
    const newArr = [...params]
    const oldVal = params[index]
    newArr[index] = { ...oldVal, value: value }
    _setParameters(newArr)
  }

  const setWeight = (index: number, weight: number): void => {
    const newArr = [...params]
    const oldVal = params[index]
    newArr[index] = { ...oldVal, weight: weight }
    _setParameters(newArr)
  }

  const availableFilters = useAvailableFilters(filterDefinitions)

  const [focusedParameterIndex, setFocusedParameterIndex] =
    React.useState<number>()

  const onAddFilterCriteria = (key: FilterValueKeys): void => {
    _setParameters([
      ...params,
      { filterValueType: key, value: null, weight: DEFAULT_PARAMETER_WEIGHT },
    ])
    setTimeout(() => setFocusedParameterIndex(params?.length))
  }

  const onRemoveFilterCriteria = (param: ScoreParameterFormModel): void => {
    const filteredParams = params.filter((oldParam) => oldParam !== param)
    _setParameters(filteredParams)
  }

  return (
    <Box>
      <Grid mt={8} gap={4} templateColumns={templateColumns}>
        <Box>
          <FormLabel mb={0}>
            <Trans>Criteria</Trans>
          </FormLabel>
          <Caption color="textMuted" maxW="sm">
            <Trans>
              Select the criteria that should influence the automatic score and
              by how much each criterion should increase the score.
            </Trans>
          </Caption>
        </Box>
        <Box>
          <FormLabel mb={0} data-stonly="parameter_score">
            <Trans>Maximum score</Trans>
          </FormLabel>
          <Caption color="textMuted">
            <Trans>
              The highest possible score is {MAX_PARAMETER_WEIGHT} points
            </Trans>
          </Caption>
          <TotalWeight params={params} goal={goal} />
          <Flex direction="column">
            <Stack
              direction="row"
              spacing={2}
              align="center"
              wrap="wrap"
              minH={8}
            >
              <Switch
                id="goal-toggle"
                size="sm"
                isChecked={toggleGoal}
                onChange={(e) => {
                  setToggleGoal(e.target.checked)
                  if (!e.target.checked) {
                    onGoalChange({
                      goal: null,
                    })
                  } else {
                    onGoalChange({
                      goal: { min: 5, max: undefined },
                    })
                  }
                }}
              />
              <FormLabel
                htmlFor="goal-toggle"
                mb="0"
                fontWeight={goal ? 'medium' : 'normal'}
                rightIcon={
                  <Tooltip
                    label={t`The goal will be visualized on score widgets on your dashboards`}
                  >
                    <span>
                      <MdInfoOutline />
                    </span>
                  </Tooltip>
                }
              >
                <Trans>Goal</Trans>
              </FormLabel>
            </Stack>
            {toggleGoal && goal && (
              <Flex>
                <ScoreGoalInput
                  range={goal}
                  onChange={(range) => {
                    onGoalChange({
                      goal: { min: range.min, max: range.max },
                    })
                  }}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </Grid>
      <Stack
        mt={4}
        spacing={2}
        divider={
          <StackDivider
            visibility={{ base: 'visible', lg: 'hidden' }}
            my={{ base: 2, lg: 1 }}
          />
        }
      >
        {(params || []).map((field, index) => {
          const filterDefinition = filterDefinitions.get(field.filterValueType)
          const initialFocusRef = React.createRef<FocusableElement>()
          return (
            <Grid
              key={index}
              templateColumns={templateColumns}
              alignItems="center"
              gap={4}
            >
              <Box minWidth={0}>
                <Popover
                  placement="bottom-start"
                  isOpen={focusedParameterIndex === index}
                  onClose={() => setFocusedParameterIndex(undefined)}
                  initialFocusRef={initialFocusRef}
                  isLazy
                >
                  {({ isOpen, onClose }) => (
                    <>
                      <PopoverTrigger>
                        <FilterCriteriaButton
                          size="sm"
                          hasValue={
                            filterDefinition?.hasValue?.(field.value) ??
                            !isEmpty(field.value)
                          }
                          leftIcon={
                            <Icon boxSize={5} as={filterDefinition?.icon} />
                          }
                          onReset={() => onRemoveFilterCriteria(field)}
                          onClick={() => setFocusedParameterIndex(index)}
                          isActive={isOpen}
                          css={css`
                            max-width: 100%;
                          `}
                        >
                          {filterDefinition?.renderText(field.value) ?? '?'}
                        </FilterCriteriaButton>
                      </PopoverTrigger>
                      <PopoverContent>
                        <React.Suspense
                          fallback={<Spinner display="block" m="1rem auto" />}
                        >
                          {filterDefinition && (
                            <filterDefinition.Component
                              onClose={onClose}
                              initialFocusRef={initialFocusRef}
                              value={field.value}
                              setValue={(value: string) => {
                                setOrRemoveVal(index, value)
                              }}
                              resetValue={() => {
                                setOrRemoveVal(index, null)
                              }}
                              options={filterDefinition.componentOptions}
                            />
                          )}
                        </React.Suspense>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              </Box>
              <Box>
                <Flex>
                  <Slider
                    min={0}
                    max={MAX_PARAMETER_WEIGHT}
                    defaultValue={field.weight ?? DEFAULT_PARAMETER_WEIGHT}
                    step={1}
                    colorScheme="primary"
                    onChange={(value) => {
                      setWeight(index, value)
                    }}
                    flex={1}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                  <Highlight ml={4} minW={6} flex="0 0 auto" textAlign="right">
                    {field.weight}
                  </Highlight>
                </Flex>
              </Box>
            </Grid>
          )
        })}
      </Stack>

      <Box mt={2}>
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<Icon as={MdAdd} boxSize={4} />}
            primary
            variant={params?.length === 0 ? 'solid' : 'ghost'}
          >
            <Trans>Add criteria</Trans>
          </MenuButton>
          <MenuList
            maxH="min(calc( 100vh - 264px ),700px)"
            overflow="auto"
            css={css`
              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            {availableFilters.map((filterGroup, i, arr) => {
              return (
                <React.Fragment key={i}>
                  {filterGroup.map((key) => {
                    const definition = filterDefinitions.get(
                      key as FilterValueKeys,
                    )
                    if (!definition) return null
                    return (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          onAddFilterCriteria(key as FilterValueKeys)
                        }}
                      >
                        <Box as={definition.icon} mr={2} />
                        {definition.name}
                      </MenuItem>
                    )
                  })}
                  {i < arr.length - 1 && <MenuDivider />}
                </React.Fragment>
              )
            })}
          </MenuList>
        </Menu>
      </Box>
    </Box>
  )
}

function useAvailableFilters(filterDefinitions: FilterDefinitions): string[][] {
  return React.useMemo(() => {
    const availableKeys = Array.from(filterDefinitions.keys()).reduce<{
      [key: string]: string[]
    }>(
      (memo, key) => {
        const definition = filterDefinitions.get(key)
        if (definition === undefined || definition.isAvailable?.() === false) {
          // Filter is not available due to fx. user role constraints or feature flags
          return memo
        }
        if (definition.inactive) {
          return memo
        }
        const { sortGroup = 'default' } = definition
        if (memo[sortGroup] === undefined) {
          memo[sortGroup] = []
        }
        memo[sortGroup].push(key)
        return memo
      },
      {
        default: [],
      },
    )
    return Object.values(availableKeys).map((group) => {
      return group.sort((aKey, bKey) => {
        const filterA = filterDefinitions.get(aKey)
        const filterB = filterDefinitions.get(bKey)
        return (filterA?.name ?? '').localeCompare(filterB?.name ?? '')
      })
    })
  }, [filterDefinitions])
}

export default Parameters
